import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends SharedClassComponent implements OnInit {
  title:string= 'System Settings';
  model_name:string='settings';

  ngOnInit() {
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      name: new FormControl('', Validators.compose([Validators.required])),
      value: new FormControl('', Validators.compose([Validators.required])),
      setting_id: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();
  // Controls the datagrid height and max rows to display
    this.observerCall();
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
  }
}
