import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from "devextreme/ui/dialog";
@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent   extends SharedClassComponent
implements OnInit
{
isInvoicePrinted: boolean;
invoiceId: any;
currentDate: Date = new Date();
invoiceData: any;
ngOnInit() {
  this.route.queryParams.subscribe((params) => {
    this.invoiceId = params.invoiceId;

    if (this.invoiceId) {
      this.getInvoiceById(this.invoiceId);
    }
  });
}

getInvoiceById(invoiceId) {
  const data = {
    requestType: "INVOICES_PAYED_MANUALLY_WAITING_APPROVAL_GET",
    invoiceId: invoiceId,
  };
  this.spinner.show();
  this.utilities.postServiceCall(data).subscribe(
    (res) => {
      const response = res.json();
      if (response.statusId == 2000) {
        const data = response.data[0];
        this.invoiceData = data;
      } else {
        this.toastr.error(response.statusMessage, "Error");
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
      this.toastr.error(
        "Something went wrong please try again",
        "Request Failed"
      );
    }
  );
}

receivedPayment(){
  const result = confirm("Are you sure you want to Confirm Received Payment ", "Confirmation");
const data={
      "requestType": "INVOICES_RECEIVE_PAYMENT",
    "invoiceId": this.invoiceId,
}

  result.then((dialogResult) => {
    if (dialogResult) {
      this.spinner.show();
      this.utilities.postServiceCall(data).subscribe(
        (res) => {
          const response = res.json();
          if (response.statusId == 2000) {
            this.toastr.success(response.statusMessage, "Success");
            
          } else {
            this.toastr.error(response.statusMessage, "Error");
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            "Something went wrong please try again",
            "Request Failed"
          );
        }
      );
    }
  });
}

}
