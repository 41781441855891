
import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogueComponent } from 'src/app/utilities/confirmation-dialogue/confirmation-dialogue.component';
import { AppSettings } from 'src/app/app-settings';
import { Http, Response, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

export class Settings {
  id: number;
  name: string;
  value: string;
}

@Injectable()
export class UtilitiesService {
  config: any;
  user_id: number; // private authService:AuthService
  token: any;
  session_data: any;
  user: any;
  billcontrol_number: number;
  billdetails: any;
  constructor(private modalService: NgbModal, private http: Http, private httpClient: HttpClient, private authService: AuthService) {
   if (this.authService.getUserDetails()) {
    this.user = this.authService.getUserDetails(),
    this.session_data = this.authService.getActiveSession();
    this.user_id = this.user.userId;
    this.token = this.session_data.token;
    }

   }
   getBillControlNumber() {
    return this.billcontrol_number;
}
setBillControlNumber(billcontrol_number) {
  this.billcontrol_number = billcontrol_number;
}
getBillControlDetails() {
  return this.billdetails;
}
setBillControlDetails(billdetails) {
this.billdetails = billdetails;
}

md5hash(data: string): string{
  return CryptoJS.MD5(data).toString();
}

    shule(keys, value): String {
      let key = CryptoJS.enc.Utf8.parse(keys);
      let iv = CryptoJS.enc.Utf8.parse(keys);
      let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return encrypted.toString();
    }

  postServiceCall(data:any, path?:any) {
    let userId = '';
    let userToken = '';
    if (this.authService.getUserDetails()) {
      let user = this.authService.getUserDetails();
      let session_data = this.authService.getActiveSession();
      userId = user.userId;
      userToken = session_data.tk;
      }
    const post_data = {
          header: {
            userId: userId,
            token: userToken,
            channelCode: AppSettings.channel_code,
            channelSecurityCode: AppSettings.channel_security_code
        },
        data
    };

    const postDataClone = {
      requestUrl:  '',
      requestBody: post_data
    };

    if (path != null || path != undefined) {
      postDataClone.requestUrl = `${AppSettings.apiName}`+path;
    } else {
      postDataClone.requestUrl = `${AppSettings.apiName}`;
    }

    //
    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_KEY_TEST);
    const headers = new Headers({
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'x-auth, content-type',
        'Access-Control-Allow-Methods': 'POST',
         'X-TPS-OCT-PLAC-7': dataHash
      });

    const wanafunzi = this.shule(AppSettings.shule, JSON.stringify(postDataClone));

    const mkuuWashule = {
      'common': wanafunzi,
      rawData: postDataClone
    }

    return this.http.post(AppSettings.phpInterceptor,
      post_data, {headers}
      )
       .pipe(map(resp => {
          return resp;
        }));
    }


    rtgsPostServiceCall(path) {
      const post_data = {};

      const headers = new Headers({
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'x-auth, content-type',
          'Access-Control-Allow-Methods': 'POST'
        });
      return this.http.post(AppSettings.rtgsApiCall + path, {headers}
        )
         .pipe(map(resp => {
            return resp;
          }));
      }

      txnPushpPostServiceCall(data:any, path) {
        let userId = '';
        let userToken = '';
        if (this.authService.getUserDetails()) {
          let user = this.authService.getUserDetails();
          let session_data = this.authService.getActiveSession();

          userId = user.id;
          userToken = session_data.token;
          }

          const post_data = {
            header: {
              userId: userId,
              token: userToken,
              channelCode: AppSettings.channel_code,
              channelSecurityCode: AppSettings.channel_security_code
          },
          data
       };

        const headers = new Headers({
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'x-auth, content-type',
            'Access-Control-Allow-Methods': 'POST'
          });
        return this.http.post(AppSettings.pushCheckBaseUrl + path,
          post_data, {headers}
          )
           .pipe(map(resp => {
              return resp;
            }));
        }
        

        postServiceCallWithoutAuth(data:any, path) {
          let userId = '';
          let userToken = '';
          if (this.authService.getUserDetails()) {
            let user = this.authService.getUserDetails();
            let session_data = this.authService.getActiveSession();

            userId = user.id;
            userToken = session_data.token;
            }
          const headers = new Headers({
              Accept: 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'x-auth, content-type',
              'Access-Control-Allow-Methods': 'POST'
            });
          return this.http.post(AppSettings.oraclebase_url + path,
            data, {headers}
            )
             .pipe(map(resp => {
                return resp;
              }));
          }



    getServiceCall(path) {
      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'x-auth, content-type',
        'Access-Control-Allow-Methods': 'POST'
      });
      return this.httpClient.get(AppSettings.rtgsApiCall + path, {headers})
        .pipe(map(res => {
           return JSON.parse(JSON.stringify(res));
        }));
      }

      printService(printEl: HTMLElement) {
        let printContainer: HTMLElement = document.querySelector('#print-container');

        if (!printContainer) {
          alert('missing');
          printContainer = document.createElement('div');
          printContainer.id = 'print-container';
        }

        printContainer.innerHTML = '';

        const elementCopy = printEl.cloneNode(true);
        printContainer.appendChild(elementCopy);
        document.body.appendChild(printContainer);

        window.print();
      }
}
