import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-data-visualization',
  templateUrl: './data-visualization.component.html',
  styleUrls: ['./data-visualization.component.scss']
})
export class DataVisualizationComponent extends SharedClassComponent implements OnInit {
  title = 'Data Visualization';
  model_name:string = 'data-visualization';
  clearedBillsBasedOnPaymentOptionsDatasource;
  rejectedBillsBasedOnRejectionReasonsDatasource;
  transactionPerServiceProviderDatasource;

  ngOnInit() {
     this.appInfo.setTitle(this.title); // sets the current page title in index.html title tag

    //  this.onGetClearedBillsdetails();
    //  this.onGetPendingBillsdetails();
    //  this.onGetPendingRejecteddetails();
    //  this.onGetServiceProvidersList('service/providers');

    //  this.clearedBillsBasedOnPaymentOptionsDatasource = this.dataService.getClearedBillsDataAnalysis();
    //  this.rejectedBillsBasedOnRejectionReasonsDatasource = this.dataService.getBillsRejectionReasonsAnalysis();
    //  this.transactionPerServiceProviderDatasource = this.dataService.getTotalTransactionsPerServiceProvider();
  }

  customizeLabel(point) {
    return point.argumentText + ': ' + point.valueText + '%';
 }

}
