import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: 'app-customer-info-list',
  templateUrl: './customer-info-list.component.html',
  styleUrls: ['./customer-info-list.component.scss']
})
export class CustomerInfoListComponent 
  extends SharedClassComponent
  implements OnInit
{
  serviceEntryType: any;
  paramswinpnlN: boolean = false;
  showAddButton = false;
  showUpdateButton = false;
  telephoneNO: any;
  name: any;
  mobileNo: any;
  Id: any;
  htelephoneNO: any;
  hname: any;
  hmobileNo: any;
  email: any;
  website: any;
  details: any;

  ngOnInit() {
    this.getcustomers();

  }

  getcustomers() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.serviceEntryType = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }




  previewDetails(e) { 
    const customerNumber=e.data.cus_no;
    const name=e.data.Name;


    this.router.navigate(['/get-customer-info'], { queryParams: { cusno: customerNumber, name: name}, queryParamsHandling: 'merge'});

    }
}
