import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';


@Component({
  selector: 'app-bill-payment-form',
  templateUrl: './bill-payment-form.component.html',
  styleUrls: ['./bill-payment-form.component.scss']
})
export class BillPaymentFormComponent  extends SharedClassComponent implements OnInit {

  contributedMembers = [];
  hideMembersContributionList = true;

  invoiceForm: FormGroup;
  qrCodeString = '';
  apiCallEndpoint = 'fumis_bill/create';
  hidePrintInvoiceButton = true;
  hidePrintMembersButton = true;
  hideGenerateControlNumberButton = true;
  hideEditInvoiceButton = true;
  hideCancelInvoiceButton = true;
  hideNoContributionMadeForInvoiceBanner =  true;
  hideInvoiceSheet = true;
  hideQrCode = true;
  membersContributionRouterLink = '/contribution/upload';

  isInvoiceCreated = true;
  currentDate= new Date();
  isInvoicePostedForPayment = false;
  isInvoicePrinted = false;
  isPenaltyInvoice = false;

  // invoice items
  controlNumber: any;
  billDescription = '';
  totalAmountPaid: any;
  payableBanks = '';
  invoiceNumber: any;
  totalAmountContributed: any;
  employerNumber: any;
  payerName: any;
  employerPhone: any;
  employerEmail: any;
  paymentType: any;
  invoiceDescription: any;
  invoiceCurrency: any;
  amountInWords = '';
  billExpireDate = '';
  invoiceBillItems = [];
  billCreationDate: any;
  now: any;
  currentYear = new Date().getFullYear();
  openInvoiceEditPopUp = false;
  contributionBatchData: any;
  isGeneratedContributionBatch = false;
  invoiceDataSource = {
      contributionInvoice: null,
      invoicePenalties: null
    };
  invoiceID = '';

 ID: any;
 description: any;
 CURRENCY: any;
 AMOUNT: any;
 userGroup: any;
 hideViewControls = false;
 individualBillDescription: any;
 spName: any;

 billData: any;


 hidePrintBtn = true;
 hideMultipleBillPrint = true;
 appName = '';
 hideBulkPrint = '0';
  expireDate: any;
  pmtOptName: any;
  payerPhone: any;
  spCode: any;
  printedBy: string;
  invoiceNo: any;
  ngOnInit() {
    this.appName = this.appInfo.title;
    this.appInfo.setTitle('Bill Order Form');
    
    const user = this.authService.getUserDetails();
  

    this.printedBy = user.firstName +' ' +user.lastName;
    this.spName= user.spName;
    if (sessionStorage.getItem(AppSettings.bulkBillerFlag) !== null && sessionStorage.getItem(AppSettings.bulkBillerFlag) !== undefined &&
    sessionStorage.getItem(AppSettings.bulkBillerFlag) == '1') {
     this.hideBulkPrint = '1';
     this.hideMultipleBillPrint = false;
    }

    if ((sessionStorage.getItem(AppSettings.billDetailsKey) === null || sessionStorage.getItem(AppSettings.billDetailsKey) === undefined) &&
    (sessionStorage.getItem(AppSettings.bulkBillerKey) === null || sessionStorage.getItem(AppSettings.bulkBillerKey) === undefined)) {
      this.toastr.error('No bill information.');
      return;
    }

    if (this.hideBulkPrint == '0') {
      try {
        this.billData = JSON.parse(sessionStorage.getItem(AppSettings.billDetailsKey));

        this.invoiceBillItems = JSON.parse(sessionStorage.getItem(AppSettings.billItemsKey));
        this.hideQrCode = false;
        this.qrCodeString = `{
          "controlNumber": "${this.billData.controlNumber}",
          "payerName": "${this.billData.payerName}",
          "totalAmount": ${this.billData.billAmount},
          "currency": "${this.billData.currency}",
          "expireDate": "${this.billData.expireDate}"
        }`;
        this.controlNumber = this.billData.controlNumber;
        this.totalAmountContributed = this.billData.billAmount;
        this.billCreationDate = this.billData.createdAt;
        this.payerName = this.billData.payerName;
        this.expireDate = this.billData.expireDate;
        this.pmtOptName = this.billData.pmtOptName;
        this.payerPhone = this.billData.payerMobile;
        this.spCode=this.billData.spCode;
        this.currency=this.billData.currency;
        this.description=this.billData.billDesc ? this.billData.billDesc : this.billData.description;

        this.invoiceNo=this.billData.invoiceNo ? this.billData.invoiceNo : this.billData.spBillId;
 
     

        this.invoiceCurrency = this.billData.currency;
        this.payableBanks = 'PBZ';

        this.hidePrintBtn = false;
        this.hideMultipleBillPrint = true;
      } catch (error) {
        this.hidePrintBtn = true;
        this.toastr.error('Request could not fulfilled.')
        console.log(error);
      }
    } else {
      try {
        this.billData = JSON.parse(this.securityService.decryptString(sessionStorage.getItem(AppSettings.bulkBillerKey)));
        this.hideQrCode = false;
        this.payableBanks = 'PBZ';
        this.hidePrintBtn = false;
        this.hideMultipleBillPrint = false;
      } catch (error) {
        this.hidePrintBtn = true;
        this.toastr.error('Request could not fulfilled.')
        console.log(error);
      }
    }
  }


getAmountInWords(amount, currency) {
  if (typeof amount !== 'number' || amount < 0 || !Number.isFinite(amount)) {
      return 'Invalid amount';
  }

  // Split amount into integer and fractional parts
  const integerPart = Math.floor(amount);
  const decimalPart = Math.round((amount - integerPart) * 100);

  const integerInWords = this.convertToWords(integerPart);
  const decimalInWords = this.convertToWords(decimalPart);

  let currencyInWords;

  switch (currency) {
      case 'USD':
          currencyInWords = integerPart === 1 ? 'Dollar' : 'Dollars';
          break;
      case 'TZS':
          currencyInWords = integerPart === 1 ? 'Tanzaniani Shilling' : 'Tanzaniani Shillings';
          break;
      default:
          return ' ';
  }

  let result = `${integerInWords} ${currencyInWords}`;

  // Add decimal part if it exists
  if (decimalPart > 0) {
      result += ` and ${decimalInWords} Cents`;
  }

  // Capitalize the first letter of the result
  // result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();

  return result;
}

 convertToWords(num) {
  if (num === 0) return 'Zero';

  const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Million", "Billion"];

  function convertHundreds(num) {
      let result = '';
      if (num > 99) {
          result += units[Math.floor(num / 100)] + ' Hundred ';
          num %= 100;
      }
      if (num > 10 && num < 20) {
          result += teens[num - 10] + ' ';
      } else {
          result += tens[Math.floor(num / 10)] + ' ';
          num %= 10;
          result += units[num] + ' ';
      }
      return result.trim();
  }

  let word = '';
  let index = 0;

  while (num > 0) {
      if (num % 1000 != 0) {
          word = convertHundreds(num % 1000) + thousands[index] + ' ' + word;
      }
      num = Math.floor(num / 1000);
      index++;
  }

  return word.trim();
}
  printInvoiceReceipt() {
    this.isInvoicePrinted = true;
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <link rel="preconnect" href="https://fonts.googleapis.com">
           <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
           <link href="https://fonts.googleapis.com/css2?family=sans-serif:wght@300;400&display=swap" rel="stylesheet">
           <style>
           body{
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
            font-family: 'sans-serif', sans-serif;
          }
          .table td{
            border-top: none !important;
            font-family: 'sans-serif', sans-serif;
          }
          p{
            font-family: 'sans-serif', sans-serif;
          }
          .space{
            display: flex;
            justify-content: space-between;
            // border: 1px dotted ##000;
            padding: 1rem 0;
          }
          .btn_control_block{
            display: flex;
            justify-content: space-evenly;
            align-content: center;
          }
          .logo__block{
          /* border-bottom: 1px dotted ##000; */
          border-bottom: 3px dotted #000;
            margin-top: 3em;
          }
          .logo__block p, .footer, p {
            color: ##000;
          }
          .invoice__head__child_contacts table tbody tr td{
          font-style: italic;
          }
          .invoice{
            background-color: #fff;
            padding: 2rem;
            width: fit-content;
            max-height: 100%;
          }
          .invoice__head,
          .invoice__how_to_pay{
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
          }
          .invoice__body{
            display: flex;
          }
            .invoice__body table {
  border-bottom: 3px dotted #000;
  border-top: 3px dotted #000;
}
          .invoice__body table tr th{
            background: ##000;
            color:white;
            text-align:left;
            vertical-align:center;
          }
          .invoice__body table tr {
           
            padding: .35em;
          }
          .logo__block, .footer{
              text-align: center;
          }
          .full_table{
            width: 100%;
          }

          table tbody tr td{
         padding: .6rem;
font-size: 20px;
font-weight: bold;
          }

          @media (max-width: 576px) {
            .btn_control_block{
              display: flex;
              flex-direction: row;
            }
           }

          @media print {
            .page-break  { display:block; page-break-before:always; }
          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            font-size: 17px;
            color:black;
            width: 100%;
            float: none;
          }
          .invoice__body table tr th{
            padding: 5px;
          }
          .footer{
            bottom: 0;
            width: 100%;
            text-align: center;
          }
          .invoice{
            background-color: transparent;
          }

          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }

            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }

           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
    this.isInvoicePrinted = false;
    // sessionStorage.removeItem(AppSettings.billDetailsKey);
  }

}
