import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../shared-class/shared-class.component';


@Component({
  selector: 'app-currency-denominations',
  templateUrl: './currency-denominations.component.html',
  styleUrls: ['./currency-denominations.component.scss']
})
export class CurrencyDenominationsComponent extends SharedClassComponent implements OnInit {
  title:string= 'Currency Denominations';
  model_name:string='currency/denominations';
  //variables defination
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  userRolesDta:any;
  param_id:any;
  response:any;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.paramwinfrm =  new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([])),
      conversion_rate: new FormControl('', Validators.compose([Validators.required])),

      currency_code: new FormControl('', Validators.compose([Validators.required])),
      id: new FormControl('', Validators.compose([])),
      denomination_id: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();
     // Controls the datagrid height and max rows to display
    this.observerCall();
  }
}
