import init from '../assets/data/init.json';

export class AppSettings {

public static channel_id = '3001';
public static channel_code = 'PT001';
public static channel_security_code = 'password';

public static ip = '172.20.1.63';
  // For LIVE  PBZ RTGS REPORTS -  TOMCAT
  public static rtgsApiCall = init.rtgsApiCall;

  public static pushCheckBaseUrl = init.pushCheckBaseUrl;

  public static operationMode = 2;
  public static userRole = "SP Admin";
  public static apiName = 'realcs_api/cx/'; // live



  public static scope = 'SP';

  public static appNme = init.appName; 
  

   public static phpInterceptor ='https://api.uax.app:8183/cx/'; // TEST 
  //  public static phpInterceptor ='http://10.0.202.85:31002/cx/'; // LIVE

  public static oraclebase_url = init.oraclebase_url;

  // Storage keys live w
  public static isLoggedInKey = '$2y$18$6MWbDSFZ7mSeRu/m/dfa.ekN7JYPaOwgenZNIWhaoXtpczEIMd/Ii';
  public static sessionKey = '$2y$18$pt36boBQmFt84UOnQC7Lfud5nlWIXUPBv5P259/mDJ12APfSGQI.K';
  public static userDataKey = '$2y$18$/d5.3e4OtdLllt2EkYEkK.Cb75dFV1lJ5AuiB9DjJdgrhm.8y1op.';

  public static XPR_TOS_KEY_TEST = '985243$#@$^@1ERF';
  public static billDetailsKey = 'a232-jy765868-46e5-a53d';
  public static billItemsKey = 'sdff9-sdff8-sfss9d7ss-sefs';

  public static shule = 'MbQeThVmYq3t6w9z$C&F)J@NcRfUjXnZ';

  public static bulkBillerKey = '3e4OtdLllt2EkYEkK$2y$18$6MWbDSFZ7mSeRu/m/dfa';

  public static bulkBillerFlag = 'Cb75dFV1lJ5jhgyujsd67437688hjdsfj';

  public static billEditKey = '4OtdLllt2EkYEkK.Cb75dFV1lJ5AuiB9D';

  public static invoiceKey = '4OtdLllt2EWWDkYEkKFF.Cb75dFV1lJFVFVFVF5FSDAuiB9D';

}
