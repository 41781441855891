import { Component, OnInit } from '@angular/core';

import {  DxContextMenuModule, DxMenuModule } from 'devextreme-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { SharedClassComponent } from '../../shared-class/shared-class.component';
@Component({
  selector: 'app-access-level',
  templateUrl: './access-level.component.html',
  styleUrls: ['./access-level.component.scss']
})
export class AccessLevelComponent extends SharedClassComponent implements OnInit {
  title:string= 'Access Levels';
  model_name:string='access/levels';
  //variables defination
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  userRolesDta:any;
  param_id:any;
  response:any;

  paramsDataSets:any;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.onGetParamsdetails();
    // Controls the datagrid height and max rows to display
    this.observerCall();
  }
}
