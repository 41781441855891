import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/app-settings';
import { map } from 'rxjs/operators';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthService {
  loggedIn = false;
  isTemporaryLogin = false;
  login_code: any;
  billcontrol_number: number;

  user_id: number; // , private authService:AuthService
  token: any;
  session_data: any;
  user: any;
  constructor(private router: Router, private http: Http,
              private spinner: SpinnerVisibilityService,
              public toastr: ToastrService, private myRoute: Router) {}

      decryptString(data) {
      const bytes  = CryptoJS.AES.decrypt(data, AppSettings.XPR_TOS_KEY_TEST);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
      }
    shule(keys, value): String {
      let key = CryptoJS.enc.Utf8.parse(keys);
      let iv = CryptoJS.enc.Utf8.parse(keys);
      let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
    }
    md5hash(data: string): string{
  return CryptoJS.MD5(data).toString();
}

getUserDetails() {
    let temp = sessionStorage.getItem(AppSettings.userDataKey);
    if (temp === null || temp === undefined) {
      return JSON.parse(temp);
    }
    temp = this.decryptString(sessionStorage.getItem(AppSettings.userDataKey));
    temp = JSON.parse(JSON.stringify(temp));
    return temp;
  }

  getSpDetails() {
    let temp = sessionStorage.getItem(AppSettings.userDataKey);
    if (temp === null || temp === undefined) {
      return JSON.parse(temp);
    }
    temp = this.decryptString(sessionStorage.getItem(AppSettings.userDataKey));
    temp = JSON.parse(JSON.stringify(temp)).sp;
    return temp;
  }

  getCategoriesDetails() {
    let temp = sessionStorage.getItem(AppSettings.userDataKey);
    if (temp === null || temp === undefined) {
      return JSON.parse(temp);
    }
    temp = this.decryptString(sessionStorage.getItem(AppSettings.userDataKey));
    temp = JSON.parse(JSON.stringify(temp)).categories;
    return temp;
  }

getActiveSession() {
    let temp = sessionStorage.getItem(AppSettings.sessionKey);
    if (temp === null || temp === undefined) {
      return JSON.parse(JSON.stringify({
        tk: 'bC-6pxhT%aPgYYnhPRCs~PmSEaKpf@HSzY$HqL'
      }));
    }
    temp = this.decryptString(sessionStorage.getItem(AppSettings.sessionKey));
    temp = JSON.parse(JSON.stringify(temp));
    return temp;
  }

resetUserpassword(data: any, model: string) {

    data.channel_code = AppSettings.channel_code,
    data.ip = AppSettings.ip,
    data.channel_security_code = AppSettings.channel_security_code;

    const postDataClone = {
      requestUrl:  `${AppSettings.apiName}`,
      requestBody: data
    };

    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_KEY_TEST);
    const httpOptions = new Headers({
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'x-auth, content-type',
        'Access-Control-Allow-Methods': 'POST',
         'X-TPS-OCT-PLAC-7': dataHash
      });

    const wanafunzi = this.shule(AppSettings.shule, JSON.stringify(postDataClone));

    const mkuuWashule = {
      'common': wanafunzi
    }

    return this.http.post(AppSettings.phpInterceptor,
      mkuuWashule, { headers: httpOptions }
    )
     .pipe(map(user => {
        return user;
      }));
  }
logIn(login: string, password: string) {
    const data = {
      header: {
        userId: "000",
        token: "",
        channelCode: "PT001",
        channelSecurityCode: "password"
    },
    data: {
        requestType: "USER_LOGIN",
        username: login,
        password: password,
        scope: AppSettings.scope
    }
    };

    

    const postDataClone = {
      requestUrl:  `${AppSettings.apiName}`,
      requestBody: data
    };

    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_KEY_TEST);
    const httpOptions = new Headers({
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'x-auth, content-type',
        'Access-Control-Allow-Methods': 'POST',
         'X-TPS-OCT-PLAC-7': dataHash
      });
    const wanafunzi = this.shule(AppSettings.shule, JSON.stringify(postDataClone));
    const mkuuWashule = {
      'common': wanafunzi,
      rawMeat: postDataClone
    }
    return this.http.post(AppSettings.phpInterceptor, data, { headers: httpOptions })
      .pipe(map(user => {
         return user;
       }));
   }

logOut() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
changeUserpassword(data: any, path: string) {
    const user = this.getUserDetails();
    const session = this.getActiveSession();

    const post_data = {
        header: {
          user_id: user.userId,
          token: session.tk,
          channel_code: AppSettings.channel_code,
          channel_security_code: AppSettings.channel_security_code
      },
      data
  };

    const postDataClone = {
      requestUrl:  `${AppSettings.apiName}`+path,
      requestBody: data
    };

    const dataHash = this.md5hash(JSON.stringify(postDataClone)+AppSettings.XPR_TOS_KEY_TEST);
    const httpOptions = new Headers({
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'x-auth, content-type',
        'Access-Control-Allow-Methods': 'POST',
         'X-TPS-OCT-PLAC-7': dataHash
      });

    const wanafunzi = this.shule(AppSettings.shule, JSON.stringify(postDataClone));

    const mkuuWashule = {
      'common': wanafunzi
    }
    return this.http.post(AppSettings.phpInterceptor,
      post_data, {headers: httpOptions}
    )
    .pipe(map(resp => {
        return resp;
      }));
  }

  get isLoggedIn() {
    let temp =  sessionStorage.getItem(AppSettings.isLoggedInKey);
    if (temp === null || temp === undefined) {
      this.loggedIn = false;
      return this.loggedIn;
    }

    temp = this.decryptString(sessionStorage.getItem(AppSettings.isLoggedInKey));
    this.login_code = temp;
    if (this.login_code == 2000) {
       this.loggedIn = true;
       this.isTemporaryLogin = false;
    } else if (this.login_code == 2113) {
      this.isTemporaryLogin = true;
      this.loggedIn = false;
    } else {
       this.loggedIn = false;
    }
    return this.loggedIn;
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const isLoggedIn = this.authService.isLoggedIn;

        const isLoginForm = route.routeConfig.path === 'login';


        if (isLoggedIn && isLoginForm) {
            this.router.navigate(['/']);
            return false;
        }

        if (!isLoggedIn && !isLoginForm) {
            this.router.navigate(['/login']);
        }

        return isLoggedIn || isLoginForm;

    }
}

// @Injectable()
// export class AuthGuardTemporaryLogin implements CanActivate {
//     constructor(private router: Router, private authService: AuthService) {}

//     canActivate(route: ActivatedRouteSnapshot): boolean {

//         const isLoginForm = route.routeConfig.path === 'login';

//         const isTempLogin = this.authService.isTempLoggedIn;

//         if (isTempLogin && isLoginForm) {
//             this.router.navigate(['/change-temporary-password']);
//             return false;
//         }

//         if (!isTempLogin && !isLoginForm) {
//             this.router.navigate(['/login']);
//         }

//         return isTempLogin;

//     }
// }

@Injectable()
export class AuthGuardServiceAdminsOnly implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private toast: ToastrService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const user = this.authService.getUserDetails();

        const expectedRole = route.data.expectedRole;
        const isLoggedIn = this.authService.isLoggedIn;
        const isLoginForm = route.routeConfig.path === 'login';

        if (isLoggedIn && isLoginForm && expectedRole.includes(user.role_id)) {
          this.router.navigate(['/']);
          return false;
      }
        if (!isLoggedIn && !isLoginForm) {
        this.toast.error('You are not authorized to access this route, login first.', 'Access Denied');
        this.router.navigate(['/login']);
    }
        if (isLoggedIn && !expectedRole.includes(user.role_id)) {
          this.toast.error('You don\'t have rights to access this route, logging you out.', 'Access Denied');
          this.authService.logOut();
      }
        return isLoggedIn || isLoginForm;

    }
}
