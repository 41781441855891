import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-service-entries-report",
  templateUrl: "./service-entries-report.component.html",
  styleUrls: ["./service-entries-report.component.scss"],
})
export class ServiceEntriesReportComponent
  extends SharedClassComponent
  implements OnInit
{
  serviceEntryList: any;
  monthList = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  institutionCode = this.authService.getUserDetails().spId;

  ngOnInit() {
  
    this.paramwinfrm = new FormGroup({
      month: new FormControl("", Validators.compose([Validators.required])),
      year: new FormControl("", Validators.compose([Validators.required])),
    });
    this.getServiceEntryInitial()
  }

  getServiceEntryInitial() {

    const data = {
      requestType: "SP_SERVICE_RECEIVERS_LIST",
    };
    this.serviceEntryList=[]
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.serviceEntryList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getServiceEntry() {

    if(this.paramwinfrm.invalid){
      this.toastr.error("Enter a Valid Year and Month", "Error");
      return;
    }

    const data = {
      requestType: "SP_SERVICE_RECEIVERS_LIST_BY_RANGE",
      month: this.paramwinfrm.get("month").value,
      year: this.paramwinfrm.get("year").value,
    };
    this.serviceEntryList=[]
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.serviceEntryList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
}
