import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppSettings } from "src/app/app-settings";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-print-invoices",
  templateUrl: "./print-invoices.component.html",
  styleUrls: ["./print-invoices.component.scss"],
})
export class PrintInvoicesComponent
  extends SharedClassComponent
  implements OnInit
{
  isInvoicePrinted: boolean;
  invoiceId: any;
  currentDate: Date = new Date();
  invoiceData: any;
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params.invoiceId;

      if (this.invoiceId) {
        this.getInvoiceById(this.invoiceId);
      }
    });
  }

  getInvoiceById(invoiceId) {
    const data = {
      requestType: "INVOICES_GET",
      invoiceId: invoiceId,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data[0];
          this.invoiceData = data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }
  printInvoiceReceipt() {
    this.isInvoicePrinted = true;
    let popUpWindow;
    const innerContents = document.getElementById("print-container2").innerHTML;
    popUpWindow = window.open(
      "",
      "_blank",
      "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
    );
    popUpWindow.document.write(`
        <html lang="en">
        <head>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=sans-serif:wght@300;400&display=swap" rel="stylesheet">
            <style>
                body {
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #333;
                    font-family: 'sans-serif', sans-serif;
                }

              .invoice-container {
    font-family: Arial, sans-serif;
    font-weight: bold;
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  .invoice-header {
    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;
    margin-top: 3em;
  }
  
  .invoice-left {
    display: flex;
    margin-top: 30px;
  }
  
  .logo {
    height: 80px;
    width: auto;
    margin-right: 10px;
  }
  
  .left-text {
    // display: flex;
    // flex-direction: column;
  }
  

  .invoice-right {

    width: 50%;
    text-align: right;
  }
  
  .invoice-info-box table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-info-box td {
    padding: 5px;
    border: 1px solid #000;
  }



  .invoice-right-info {

    width: 50%;
    text-align: center;
  }

  
  
  .invoice-content {
    display: flex;
    justify-content: space-between;

    padding: 10px 0;
  }
  
  .invoice-left-info
{
    width: 48%;
  }

  .invoice-right-info {
    text-align: center;
  }
  
  .invoice-right-info td {
    padding: 5px;
    border: 1px solid #000;
  }
  
  .invoice-right-info table {
    width: 100%;
    border-collapse: collapse;
  }

    .invoice-left-info table {
    width: 90%;
    border-collapse: collapse;
  }
  
  
  .invoice-left-info td {
    padding: 5px;
    border: 1px solid #000;
  }

  .invoice-dates {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .invoice-dates table {
    border-collapse: collapse;
  }
  
  .invoice-dates td {
    padding: 5px;
    border: 1px solid #000;
    text-align: left;
  }
  
  .invoice-details table {
    width: 100%;

    border-collapse: collapse;
    margin-top: 20px;

  }
  
  .invoice-details th{
    border: 1px solid #000;
    padding: 8px;
    text-align: left;

  }


  .invoice-details td {
    border: 1px solid #000;
    padding: 8px;
    text-align: start;
    height: 450px;
    vertical-align: top;
}
  
  .invoice-summary {
    display: flex;
    justify-content: space-between;
    
  }
  
  .summary-left {
    width: 70%;
    margin-top: 25px;
  }

   .summary-right {
    width: 30%;
 
  }


  .summary-left table {
    width: 100%;

    border-collapse: collapse;
  }
  
  .summary-left td {
    padding: 12px;
    border: 1px solid #000;
    border-right: none;
    text-align: right;
  }


  
  .summary-right table {
    width: 100%;
    border-top: none;
    border-collapse: collapse;
  }
  
  .summary-right td {
    padding: 5px;
    border: 1px solid #000;
    text-align: right;
  }
  
  .invoice-footer {
    display: flex;
    justify-content: space-between;
  
    padding-top: 10px;
    margin-top: 20px;
  }

  .large-text {
  font-size: 24px;
  }
  
  .footer-left {
    width: 48%;
  }
  
  .footer-right {
    width: 48%;
    margin-top: 3em;
  }
  
  .footer-right table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .footer-right td {
    padding: 5px;
    border: 1px solid #000;
    text-align: right;
  }
  

            

                @media (max-width: 576px) {
                    .btn_control_block {
                        display: flex;
                        flex-direction: row;
                    }
                }

                @media print {
                    .page-break { display: block; page-break-before: always; }

                    @page {
                        size: A4;
                        margin: 0mm;
                    }

                    html, body {
                        width: 1024px;
                    }

                    body {
                        margin: 0 auto;
                        line-height: 1em;
                        word-spacing: 2px;
                        letter-spacing: 0.2px;
                        font-size: 17px;
                        color: black;
                        width: 100%;
                        float: none;
                    }

               

                    .footer {
                        bottom: 0;
                        width: 100%;
                        text-align: center;
                    }

             

                    h1 {
                        font: 28px "Times New Roman", Times, serif;
                    }

                    h2 {
                        font: 25px "Times New Roman", Times, serif;
                    }

                    h3 {
                        font: 20px "Times New Roman", Times, serif;
                    }

                    a:link, a:visited {
                        color: #781351;
                        background: transparent;
                        text-decoration: none;
                    }

                    a[href]:after {
                        content: "" !important;
                    }

                    a[href^="http://"] {
                        color: #000;
                    }

                    #header {
                        height: 75px;
                        font-size: 24pt;
                        color: black;
                    }
                }
            </style>
        </head>
        <body onload="window.print()">${innerContents}</body>
        </html>
    `);
    popUpWindow.document.close();
    sessionStorage.removeItem(AppSettings.billDetailsKey);
  }
}
