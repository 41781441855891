import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AppSettings } from 'src/app/app-settings';

@Injectable({
  providedIn: 'root'
})
export class EncryptionDecryptionService {

  constructor() { }

    // The set method is use for encrypt the value.
    set(keys, value) {
      let key = CryptoJS.enc.Utf8.parse(keys);
      let iv = CryptoJS.enc.Utf8.parse(keys);
      let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return encrypted.toString();
    }

    // The get method is use for decrypt the value.
    get(keys, value) {
      let key = CryptoJS.enc.Utf8.parse(keys);
      let iv = CryptoJS.enc.Utf8.parse(keys);
      let decrypted = CryptoJS.AES.decrypt(value, key, {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    }

    encryptString(data): string {
      const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), AppSettings.XPR_TOS_KEY_TEST).toString();
      return ciphertext;
      }

      decryptString(data) {
      const bytes  = CryptoJS.AES.decrypt(data, AppSettings.XPR_TOS_KEY_TEST);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
      }
}
