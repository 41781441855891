import { Component, OnDestroy, OnInit } from "@angular/core";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-view-cleared-bills",
  templateUrl: "./view-cleared-bills.component.html",
  styleUrls: ["./view-cleared-bills.component.scss"],
})
export class ViewClearedBillsComponent
  extends SharedClassComponent
  implements OnInit
{
  now: Date = new Date();

  institutionName = this.authService.getUserDetails().spName;
  institutionCode = this.authService.getUserDetails().spId;
  title = "Create Bill";
  billInformation: any;

  billId = null;

  ngOnInit() {

    // this.billInformation = data[0];
    console.log(this.billInformation);
    this.appInfo.setTitle(this.title);

    this.route.queryParams.subscribe((params) => {
      this.billId = params.billId;

      if (this.billId) {
        this.getInvoiceById(this.billId);
      }
    });

    if (!this.billId) {
      return;
    }
  }

  getInvoiceById(billId) {
    const data = {
      requestType: "CLEARED_BILLS_GET",
      billid: billId,
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        this.spinner.hide();
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data.clearedBills[0];

          this.billInformation = data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  printInvoice(billId) {

    this.router.navigate(['/preview-cleared-bills'], { queryParams: { billId: billId }, queryParamsHandling: 'merge'});
  }
}
