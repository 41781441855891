import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends SharedClassComponent implements OnInit {

  title ="User Profile"
  userDetailsDatasource:any=[]
  changePasswordWin=false
  changePasswordFrm: FormGroup;

  ngOnInit() {
    this.changePasswordFrm = new FormGroup({
      requestType: new FormControl('SP_USER_PASSWORD_CHANGE', Validators.compose([Validators.required])),
      oldPassword: new FormControl('', Validators.compose([Validators.required])),
      newPassword: new FormControl('', Validators.compose([Validators.required])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required]))
    });
    
    this.getServiceProviderAccounts()
  }


  getServiceProviderAccounts() {
    const data = {
      "requestType": "SP_USER_INFO",
      "spUserId": sessionStorage.getItem("userId")
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(res => {
      const srvRes = res.json();
      
      if (srvRes.statusId == 2000) {
        this.userDetailsDatasource = srvRes.data;
        
      }
      this.spinner.hide();
    }, error => {
      this.toastr.error(error);
      this.spinner.hide();
    });
  }

  funcChangeUserPassword()
  {
    this.changePasswordWin=true
  }

  
  OnPasswordReset() {
    if (this.changePasswordFrm.invalid) {
      this.toastr.error('Fill in all the form details', 'Alert');
      return;
    }
    // check the details
    const newPassword = this.changePasswordFrm.get('newPassword').value;
    const  confirmPassword = this.changePasswordFrm.get('confirmPassword').value;
    if (newPassword !== confirmPassword) {
      this.toastr.error('Password Mismatch, please try again', 'Alert');
      this.changePasswordFrm.get('newPassword').setValue(null),
        this.changePasswordFrm.get('confirmPassword').setValue(null);
      return;
    }

    const data ={
      "requestType":"SP_USER_PASSWORD_CHANGE",
      "userId":sessionStorage.getItem("userId"),
      "oldPassword":this.changePasswordFrm.get("oldPassword").value,
      "newPassword":this.changePasswordFrm.get("newPassword").value
    }
    this.spinner.show();
    this.utilities.postServiceCall(data,'change-password')
      .subscribe(
        response => {
          this.response = response.json();
          console.log(response.json())
          if (this.response.statusId == 2000) {
            this.changePasswordWin = false;
            this.toastr.success('Password Changed Successfully!', 'Response');
            this.changePasswordFrm.reset();
            this.changePasswordFrm.get('requestType').patchValue('SP_USER_PASSWORD_CHANGE');
          } else {
            this.toastr.error(this.response.statusMessage, 'Alert');
          }
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.toastr.error('Something went wrong, please try again!! Error -> ' + error, 'Alert');
        }
      );
  }

}
