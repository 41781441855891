import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
  userPermissions = []
  constructor(public authService: AuthService,private router: Router){
    this.userPermissions = this.authService.getUserDetails().permissions
    //console.log("LOGGED USER PERMISSION GUARD",this.userPermissions)
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
// console.log("USER DATA IS");
// console.log(this.authService.getUserDetails());

      const expectedPermission = next.data.expectedPermission;
      // console.log("authorization")
      // console.log(expectedPermission);
      // console.log(this.userPermissions);
if(!this.userPermissions){
  this.router.navigate(['/fumis/forbidden']);
return false;
}
      const hasPermission = this.userPermissions.some(
        (p) => expectedPermission.includes(p.permission) && p.permissionName === "true"
      );

      //console.log("USER HAS PERMISSIONS", hasPermission);
  
      if (!hasPermission) {
        this.router.navigate(['/fumis/forbidden']);
      }
  
      return hasPermission;
  }
  
}
