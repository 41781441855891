import { Component, OnInit, NgModule, Input } from '@angular/core';
import {
  SideNavigationMenuModule,
  HeaderModule
} from '../../shared/components';
import notify from 'devextreme/ui/notify';

import { ScreenService, AuthService } from '../../shared/services';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';

import { navigation } from '../../app-navigation';
import { Router, NavigationEnd } from '@angular/router';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit {
  menuItems: any; // navigation;
  selectedRoute = '';
  data = {
    setting_id: '1008'
  };
  getIdleTimeoutByName = {
    setting_name: 'IDLE_TIMEOUT_IN_SECONDS'
  };
  getSettingsByNameEndpoint = 'settings/name';
  endPoint = 'settings/get';
  idleTimeout: number;

  menuOpened: boolean;
  temporaryMenuOpened = false;
  userPermissions = [];
  userPermissionsFilter: any;

  @Input()
  title: string;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  user: any;
  selectedRole: string;
  filteredMenuItems: any[];
  role_id: number;
  constructor(
    private bnIdle: BnNgIdleService,
    private screen: ScreenService,
    private router: Router,
    public utilities: UtilitiesService,
    public spinner: SpinnerVisibilityService,
    public authService: AuthService
  ) {
    const isLoginForm = router.url === '/login';
    this.user = this.authService.getUserDetails();
    this.selectedRole=this.user.usergroup;
      // (this.role_id = this.user.role_id);
    // this.onUserRolesNavigationssdetails();

this.newMenu();
this.updateMenu(this.user)
  }

  ngOnInit() {
    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  updateMenu(user){
    this.userPermissions = user.permissions;
          this.filteredMenuItems = this.filterMenuItems(this.menuItems);
          setTimeout(() => {
            this.menuItems = this.filterMenuItems(this.menuItems);
          });
  }
  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 0;
    this.shaderEnabled = !isLarge;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }
  // load the navigation details

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      if (event.node.selected) {
        pointerEvent.preventDefault();
      } else {
        this.router.navigate([path]);
      }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  }



  // userGet() {
  //   const data = {
  //     requestType: "USERS_GET_FUMIS",
  //     userID: this.userID,
  //   };

  //   this.utilities.postServiceCall(data).subscribe(
  //     (res) => {
  //       const response = res.json();
  //       if (response.code == 2000) {
  //         this.userPermissions = response.data[0].p;
  //         this.filteredMenuItems = this.filterMenuItems(this.menuItems);
  //         setTimeout(() => {
  //           this.menuItems = this.filterMenuItems(this.menuItems);
  //         });
  //       } else {
  //       }
  //     },
  //     (error) => {}
  //   );
  // }

  newMenu(){
    this.menuItems = [


      {
        text: 'Profile',
        icon: 'fa fa-users',
        path: 'profile'
      },
      {
        text: 'Dashboard',
        icon: 'fa fa-th',
        path: 'home',
      },
  
      
      


      {
        text: 'Invoices',
      icon: 'fa fa-calculator',
      accessName: "",
        items: [
          {
            text: 'Create Invoice',
          
            path: 'invoice/create',
            accessName: "Create Invoice",
          },    
          {
            text: 'Generated Invoices',
            
            path: 'genereted-invoices',
            accessName: "View Generated Invoice",
          },
        
        ]
      },
  

      {
        text: 'Bills management',
        icon: 'fa fa-tasks',
        accessName: "",
        items: [
          {
            text: 'Bill Create',
   
            path: 'bill/create',
            accessName: "Create Bill",
          },
          {
            text: 'Bulk Bill Create',
    
            path: 'bulk/bill/create',
            accessName: "Create Bill",
          },
          {
            text: 'Generated Bills',
       
            path: 'generated-bills',
            accessName: "View Generated Bills",
          },
        ]
      },

        
      {
        text: 'Reconciliation ',
        icon: 'fa fa-renren',
        accessName: "",
        items: [
          {
            text: 'Transaction',   
            path: 'recon-transaction',
            accessName: "Transaction",
          },
          {
            text: 'Exception report',    
            path: 'recon-exception-report',
            accessName: "Exception report",
          },
         
        ]
      },

        {
        text: 'Customers',
        icon: 'fa fa-users',
        path: 'customer-info-list',
        accessName: "View Customers",

        

      },
      {
        text: 'Service Entry',
        icon: 'fa fa-wpexplorer',
        path: 'service-entries-report',
        accessName: "View Service Entry",
      },


      {
        text: 'Administration',
        icon: "fa fa-shield",
        accessName: "",
        items: [

          {
            text: 'System Users',
    
            path: 'users-list',
            accessName: "View System Users",
          },
          {
            text: 'Configurations',
            accessName: "",
            items: [
              {
                text: 'Service Categories',
            
                path: 'sp/categories',
                accessName: "View Service Categories",
              },
              {
                text: 'Customer List',
            
                path: 'customers-list',
                accessName: "View Customer List (a)",
              },
              {
                text: 'Service Entry',
               
                path: 'service-entry',
                accessName: "View Service Entry (a)",
              },

              {
                text: 'Usergroup',                 
                path: 'usergroup',
                accessName: "View Usergroup (a)",
              },
            
            ]
          },
        ]

      },

     
      {
        text: 'Report',
        icon: 'fa fa-file-text-o',
        accessName: "",
        items: [
          {
            text: 'Bills Listing',
            path: 'reports/bills/listing',
            accessName: "Bill Listing Report (r)",
          },
    
    

          {
            text: 'Bills Cleared',
            path:  'reports/bills/cleared',
            accessName: "Bill Cleared Report (r)",
          },
          {
            text: 'Bills Expired',
            path: 'reports/bills/expired',
            accessName: "Bill Expired Report (r)",
          },
          {
            text: 'Audit Trail',
            path: 'reports/audit-trail',
            accessName: "Audit Trail Report (r)",
          }
        ]
      },
    
    ];
  }
  onUserRolesNavigationssdetails() {
    // var data = {
    //   role_id: this.role_id
    // };
    // "usergroup": "SP Admin",



    if(AppSettings.userRole === this.selectedRole) {
      this.menuItems = [
        {
          text: 'Dashboard',
          icon: 'fa fa-th',
          path: 'home',
        },
        {
          text: 'Profile',
          icon: 'fa fa-users',
          path: 'profile'
        },
      
        {
          text: 'Invoices',
        icon: 'fa fa-calculator',
          items: [
            {
              text: 'Create Invoice',
            
              path: 'invoice/create',
            },    
            {
              text: 'Generated Invoices',
              
              path: 'genereted-invoices'
            },
          
          ]
        },

        {
          text: 'Bills management',
          icon: 'fa fa-tasks',
          items: [
            {
              text: 'Bill Create',
     
              path: 'bill/create',
            },
            {
              text: 'Bulk Bill Create',
      
              path: 'bulk/bill/create',
            },
            {
              text: 'Generated Bills',
         
              path: 'generated-bills'
            },
          ]
        },
        {
          text: 'Customers',
          icon: 'fa fa-user-o',
          path: 'customer-info-list'
        },

        {
          text: 'Service Entry',
          icon: 'fa fa-wpexplorer',
          path: 'service-entries-report'
        },
        {
          text: 'Configurations',
          icon: 'fa fa-random',
          items: [
            {
              text: 'Service Categories',
          
              path: 'sp/categories'
            },
            {
              text: 'Customer List',
          
              path: 'customers-list'
            },
            {
              text: 'Service Entry',
             
              path: 'service-entry'
            },
          
          ]
        },
       
     
      
        {
          text: 'Report',
          icon: 'fa fa-file-text-o',
          items: [
            {
              text: 'Bills Listing',
              path: 'reports/bills/listing'
            },
            // {
            //   text: 'Bill Details',
            //   path: 'reports/bills/details'
              
            // },
      

            {
              text: 'Bills Cleared',
              path:  'reports/bills/cleared',
            },
            {
              text: 'Bills Expired',
              path: 'reports/bills/expired'
            },
            {
              text: 'Audit Trail',
              path: 'reports/audit-trail'
            }
          ]
        },
      
      ];
    } else {
      this.menuItems = [
        {
          text: 'Dashboard',
          icon: 'fa fa-th',
          path: 'home',
        },
    
        
        


        {
          text: 'Invoices',
        icon: 'fa fa-calculator',
          items: [
            {
              text: 'Create Invoice',
            
              path: 'invoice/create',
            },    
            {
              text: 'Generated Invoices',
              
              path: 'genereted-invoices'
            },
          
          ]
        },
    

        {
          text: 'Bills management',
          icon: 'fa fa-tasks',
          items: [
            {
              text: 'Bill Create',
     
              path: 'bill/create',
            },
            {
              text: 'Bulk Bill Create',
      
              path: 'bulk/bill/create',
            },
            {
              text: 'Generated Bills',
         
              path: 'generated-bills'
            },
          ]
        },
       {
          text: 'Customers',
          icon: 'fa fa-users',
          path: 'customer-info-list'
        },
        {
          text: 'Service Entry',
          icon: 'fa fa-wpexplorer',
          path: 'service-entries-report'
        },


        {
          text: 'Administration',
          icon: "fa fa-shield",
          items: [

            {
              text: 'Service providers',
      
              path: 'users-list'
            },
            {
              text: 'Configurations',
        
              items: [
                {
                  text: 'Service Categories',
              
                  path: 'sp/categories'
                },
                {
                  text: 'Customer List',
              
                  path: 'customers-list'
                },
                {
                  text: 'Service Entry',
                 
                  path: 'service-entry'
                },

                {
                  text: 'Usergroup',                 
                  path: 'usergroup'
                },
              
              ]
            },
          ]

        },
    
       
        {
          text: 'Report',
          icon: 'fa fa-file-text-o',
          items: [
            {
              text: 'Bills Listing',
              path: 'reports/bills/listing'
            },
      
      

            {
              text: 'Bills Cleared',
              path:  'reports/bills/cleared',
            },
            {
              text: 'Bills Expired',
              path: 'reports/bills/expired'
            },
            {
              text: 'Audit Trail',
              path: 'reports/audit-trail'
            }
          ]
        },
      
      ];
    }
    // if (AppSettings.operationMode == 1) {
    //   this.menuItems = [
    //     {
    //       text: 'Dashboard',
    //       icon: 'fa fa-th-large',
    //       path: 'home',
    //     },
    //     {
    //       text: 'User Management',
    //       icon: 'fa fa-user-plus',
    //       path: 'user-management'
    //     },
    // {
    //   text: 'Profile',
    //   icon: 'fa fa-user-o',
    //   path: 'profile'
    // },
    //     {
    //       text: 'Service Providers',
    //       icon: 'fa fa-university',
    //       path: 'register/institution'
    //     },
    //     {
    //       text: 'Service Provider Accounts',
    //       icon: 'fa fa-credit-card-alt',
    //       path: 'services/providers/accounts'
    //     }
    //   ];
    // } else if(AppSettings.operationMode == 2) {
    //   this.menuItems = [
    //     {
    //       text: 'Dashboard',
    //       icon: 'fa fa-th-large',
    //       path: 'home',
    //     },
    //     {
    //       text: 'Profile',
    //       icon: 'fa fa-user',
    //       path: 'profile'
    //     },
    //     {
    //       text: 'Bill Create',
    //       icon: 'fa fa-calculator',
    //       path: 'bill/create',
    //     },
    //     {
    //       text: 'Bulk Bill Create',
    //       icon: 'fa fa-list-ol',
    //       path: 'bulk/bill/create',
    //     },
    //     {
    //       text: 'Generated Bills',
    //       icon: 'fa fa-list-alt',
    //       path: 'generated-bills'
    //     },
        // {
        //   text: 'Service Categories',
        //   icon: 'fa fa-random',
        //   path: 'sp/categories'
        // },
        // {
        //   text: 'User Management',
        //   icon: 'fa fa-user-plus',
        //   path: 'user-management'
        // },
    //     {
    //       text: 'Report',
    //       icon: 'fa fa-file-excel-o',
    //       items: [
    //         {
    //           text: 'Bills Listing',
    //           path: 'reports/bills/listing'
    //         },
    //         // {
    //         //   text: 'Bill Details',
    //         //   path: 'reports/bills/details'
              
    //         // },
      

    //         {
    //           text: 'Bills Cleared',
    //           path:  'reports/bills/cleared',
    //         },
    //         {
    //           text: 'Bills Expired',
    //           path: 'reports/bills/expired'
    //         },
    //         {
    //           text: 'Audit Trail',
    //           path: 'reports/audit-trail'
    //         }
    //       ]
    //     },
      
    //   ];
    // } else {
    //   this.menuItems = [
    //     {
    //       text: 'Dashboard',
    //       icon: 'fa fa-th-large',
    //       path: 'home',
    //     },
    //     {
    //       text: 'Profile',
    //       icon: 'fa fa-user',
    //       path: 'profile'
    //     },
    //     {
    //       text: 'Bill Create',
    //       icon: 'fa fa-calculator',
    //       path: 'bill/create',
    //     },
    //     {
    //       text: 'Bulk Bill Create',
    //       icon: 'fa fa-list-ol',
    //       path: 'bulk/bill/create',
    //     },
    //     {
    //       text: 'Generated Bills',
    //       icon: 'fa fa-list-alt',
    //       path: 'generated-bills'
    //     },
    //     {
    //       text: 'Service Provider Accounts',
    //       icon: 'fa fa-credit-card-alt',
    //       path: 'services/providers/accounts'
    //     },
    //     {
    //       text: 'User Management',
    //       icon: 'fa fa-user-plus',
    //       path: 'user-management'
    //     },
    //     {
    //       text: 'Service Providers',
    //       icon: 'fa fa-university',
    //       path: 'register/institution'
    //     },
    //     {
    //       text: 'Service Categories',
    //       icon: 'fa fa-random',
    //       path: 'sp/categories'
    //     },
    //     {
    //       text: 'Report',
    //       icon: 'fa fa-file-excel-o',
    //       items: [
    //         {
    //           text: 'Bills Listing',
    //           path: 'reports/bills/listing'
    //         },
    //         // {
    //         //   text: 'Bill Details',
    //         //   path: 'reports/bills/details'
    //         // },
    //         {
    //           text: 'Bills Expired',
    //           path: 'reports/bills/expired'
    //         },
    //         {
    //           text: 'Audit Trail',
    //           path: 'reports/audit-trail'
    //         }
    //       ]
    //     },
       
    //   ];
    // }

    // this.menuItems = navigation;
    // this.utilities.postServiceCall(data, 'role/navigations').subscribe(
    //   response => {
    //     let data = response.json();
    //     if (data.data) {
    //       this.menuItems = data.data;
    //     } else {
    //       this.menuItems = [
    //         {
    //           text: 'Dashboard',
    //           items: [
    //             {
    //               text: 'Bills Validation',
    //               path: 'bill-validation'
    //             }
    //           ]
    //         }
    //       ];
    //     }
    //   },
    //   error => {
    //     this.spinner.hide();
    //   }
    // );
  }

  filterMenuItems(items: any[]): any[] {
    if (!this.userPermissions) {
      console.error("this.userPermissions is undefined or null");
      return [];
    }

    this.userPermissionsFilter = this.userPermissions
      .filter((permission) => permission.permissionName === 'true')
      .map((permission) => permission.permission);

    return items
      .map((item) => {
        const filteredItem: any = { ...item };

        if (item.text === "Profile") {
   
          return item;
        }


        if (item.text === "Dashboard") {
         
          return item;
        }

        // Check if the item itself has an assigned role only if items is null
        if (
          !item.items &&
          item.accessName &&
          this.userPermissionsFilter.includes(item.accessName)
        ) {
          return item;
        }

        // If items is not null, check if any child item has an assigned role
        if (item.items && item.items.length > 0) {
          const filteredItems = this.filterMenuItems(item.items);
          if (filteredItems.length > 0) {
            filteredItem.items = filteredItems;
            return filteredItem;
          } else {
            return null;
          }
        }

        return null;
      })
      .filter(Boolean);
  }
  checkAccess(item: any): boolean {
    // Check if the current item or any of its child items have the required access
    if (
      item.accessName &&
      this.userPermissionsFilter.includes(item.accessName)
    ) {
      return true;
    } else if (!item.accessName && item.items && item.items.length > 0) {
      return item.items.some((childItem) => this.checkAccess(childItem));
    }

    return false;
  }

  replaceKey($subject, $newKey, $oldKey) {}
  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [
    SideNavigationMenuModule,
    DxDrawerModule,
    HeaderModule,
    DxScrollViewModule,
    CommonModule
  ],
  exports: [SideNavOuterToolbarComponent],
  declarations: [SideNavOuterToolbarComponent]
})
export class SideNavOuterToolbarModule {}
