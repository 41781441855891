import {
  Component,
  NgModule,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthService, AppInfoService } from "../../services";
import { UserPanelModule } from "../user-panel/user-panel.component";
import { UtilitiesService } from "../../services/utilities.service";
import { DxButtonModule } from "devextreme-angular/ui/button";
import { DxToolbarModule } from "devextreme-angular/ui/toolbar";
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { Router } from "@angular/router";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import {
  DxDataGridModule,
  DxFormModule,
  DxContextMenuModule,
  DxMenuModule,
  DxDateBoxModule,
  DxTextBoxModule,
  DxPopupModule,
} from "devextreme-angular";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  appName: string;
  roleName: string = "";
  endpoint: string = "roles/get";
  showUpdateLogo: boolean = false;
  data = {
    role_id: 0,
  };
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;
  imageSelected: boolean = false;
  model_name: string = "users/password";
  endpointcall: string = "change";
  response: any;
  @Input()
  title: string;
  changePasswordFrm: FormGroup;
  changePasswordWin: boolean;
  userMenuItems = [];

  imageSrc: string | undefined;
  imageSrc1: string | undefined;
  base64Image: any;
  selectedValue: any;

  logo: any;
  usergroup: any;
  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private spinner: SpinnerVisibilityService,
    public toastr: ToastrService,
    private utilities: UtilitiesService
  ) {
    this.appName = this.appInfo.title;
    this.changePasswordFrm = new FormGroup({
      old_password: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      new_password: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      confirm_password: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    const user = this.authService.getUserDetails();

    this.roleName = user.firstName + " " + user.lastName + " | " + user.spAlias;
    this.logo = user.logo;
    this.usergroup = user.usergroup;
  }
  ngOnInit(): void {
    this.appName = "AIRPORT BILLING SYSTEM";
  }
  onFileSelected(event: any) {
    if (event) {
      this.imageSelected = true;
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result.toString();
        this.base64Image = reader.result.toString().split(",")[1];
        // this.memberCapturedImage=this.imageSrc;
        //this.saveImageToDatabase(this.base64Image);
      };
    } else {
      this.imageSelected = true;
    }
  }

  showUpdateLogos() {
    if (this.usergroup === "SP Admin") {
      this.showUpdateLogo = true;
    }
  }
  toggleMenu = () => {
    this.menuToggle.emit();
  };
  redirectToHome = () => {
    if (this.router.url !== "home") {
      this.router.navigate(["/home"]);
    }
  };
  funcUserLogOut() {
    this.authService.logOut();
  }
  funcChangeUserPassword() {
    this.changePasswordWin = true;
  }
  funcpopWidth(percentage_width) {
    if (window.innerWidth > 800) {
      return (window.innerWidth * percentage_width) / 100;
    } else {
      return window.innerWidth - 50;
    }
  }
  OnPasswordReset() {
    if (this.changePasswordFrm.invalid && !this.changePasswordFrm.touched) {
      this.toastr.error("Fill in all the form details", "Response");
      return;
    }

    if (!this.changePasswordFrm.get("new_password").valid) {
      this.toastr.error(
        "Password must be 6 or more characters, with a capital letter, small letter, a number and a special character"
      );
      return;
    }
    // check the details
    const new_password = this.changePasswordFrm.get("new_password").value;
    const confirm_password =
      this.changePasswordFrm.get("confirm_password").value;

    if (new_password != confirm_password) {
      this.toastr.error("Password didn't match", "Response");
      this.changePasswordFrm.get("new_password").setValue(""),
        this.changePasswordFrm.get("confirm_password").setValue("");
      return;
    }
    this.authService
      .changeUserpassword(
        this.changePasswordFrm.value,
        this.model_name + "/" + this.endpointcall
      )
      .subscribe(
        (response) => {
          this.response = response.json();

          if (this.response.statusId == 2000) {
            this.changePasswordWin = false;
            this.toastr.success("Password Changed Successfully!", "Response");
            this.changePasswordFrm.reset();
            this.changePasswordFrm.get("new_password").setValue(""),
              this.changePasswordFrm.get("confirm_password").setValue("");
            this.changePasswordFrm.get("old_password").setValue("");
          } else {
            this.toastr.error(this.response.statusMessage, "Alert");
            this.changePasswordFrm.reset();
            this.changePasswordFrm.get("new_password").setValue(""),
              this.changePasswordFrm.get("confirm_password").setValue("");
            this.changePasswordFrm.get("old_password").setValue("");
          }
          this.spinner.hide();
        },
        (error) => {
          this.toastr.error(error, "Response");
        }
      );
  }

  savePicture() {
    if (!this.imageSrc) {
      this.toastr.error("Image is Required", "Failed");
      return;
    }

    const data = {
      requestType: "SP_ADD_LOGO",
      spId: sessionStorage.getItem("spId"),

      logo: this.imageSrc,
    };
    this.spinner.show();
    console.log(data);
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();
        this.showUpdateLogo = false;
        //console.log(srvRes)
        if (srvRes.statusId == 2000) {
          this.logo = this.imageSrc;
          this.toastr.success("Operation succesfull");
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Currency",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxDataGridModule,
    DxFormModule,
    DxContextMenuModule,
    DxMenuModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,

    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
