import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppSettings } from "src/app/app-settings";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";
@Component({
  selector: "app-preview-cleared-bills",
  templateUrl: "./preview-cleared-bills.component.html",
  styleUrls: ["./preview-cleared-bills.component.scss"],
})
export class PreviewClearedBillsComponent
  extends SharedClassComponent
  implements OnInit
{
  currentDate: Date = new Date();

  institutionName = this.authService.getUserDetails().spName;
  institutionCode = this.authService.getUserDetails().spId;
  title = "Create Bill";
  billInformation: any;

  billId = null;
  printedBy: string;
  spName: any;
  appName: string;

  ngOnInit() {
    this.appName = this.appInfo.title;
    const user = this.authService.getUserDetails();

    this.printedBy = user.firstName + " " + user.lastName;
    this.spName = user.spName;

    this.appInfo.setTitle(this.title);

    this.route.queryParams.subscribe((params) => {
      this.billId = params.billId;

      if (this.billId) {
        this.getInvoiceById(this.billId);
      }
    });

    if (!this.billId) {
      return;
    }
  }

  getInvoiceById(billId) {
    const data = {
      requestType: "CLEARED_BILLS_GET",
      billid: billId,
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        this.spinner.hide();
        const response = res.json();
        if (response.statusId == 2000) {
          const data = response.data.clearedBills[0];

          this.billInformation = data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getAmountInWords(amount, currency) {
    if (typeof amount !== "number" || amount < 0 || !Number.isFinite(amount)) {
      return "Invalid amount";
    }

    const integerPart = Math.floor(amount);
    const decimalPart = Math.round((amount - integerPart) * 100);

    const integerInWords = this.convertToWords(integerPart);
    const decimalInWords = this.convertToWords(decimalPart);

    let currencyInWords;

    switch (currency) {
      case "USD":
        currencyInWords = integerPart === 1 ? "Dollar" : "Dollars";
        break;
      case "TZS":
        currencyInWords =
          integerPart === 1 ? "Tanzaniani Shilling" : "Tanzaniani Shillings";
        break;
      default:
        return " ";
    }

    let result = `${integerInWords} ${currencyInWords}`;

    if (decimalPart > 0) {
      result += ` and ${decimalInWords} Cents`;
    }

    return result;
  }

  convertToWords(num) {
    if (num === 0) return "Zero";

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    function convertHundreds(num) {
      let result = "";
      if (num > 99) {
        result += units[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num > 10 && num < 20) {
        result += teens[num - 10] + " ";
      } else {
        result += tens[Math.floor(num / 10)] + " ";
        num %= 10;
        result += units[num] + " ";
      }
      return result.trim();
    }

    let word = "";
    let index = 0;

    while (num > 0) {
      if (num % 1000 != 0) {
        word = convertHundreds(num % 1000) + thousands[index] + " " + word;
      }
      num = Math.floor(num / 1000);
      index++;
    }

    return word.trim();
  }


printInvoiceReceipt() {
  let popUpWindow;
  const innerContents = document.getElementById("print-container2").innerHTML;

  popUpWindow = window.open(
    "",
    "_blank",
    "scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no"
  );

  popUpWindow.document.write(`
      <html lang="en">
          <head>
              <style>
                  body {
                      font-size: 14px;
                      line-height: 1.42857143;
                      color: #333;
                      font-family: 'Arial', sans-serif;
                  }
                  .table td {
                      border-top: none !important;
                      font-family: 'Arial', sans-serif;
                  }
                  p {
                      font-family: 'Arial', sans-serif;
                  }
                  .bill-container {
                      font-family: Arial, sans-serif;
                      width: 800px;
                      margin: 0 auto;
                      padding: 20px;
                      border: 1px solid #ddd;
                      background-color: #fff;
                  }
                  .space {
                      display: flex;
                      justify-content: space-between;
                      padding: 1rem 0;
                  }
                  .btn_control_block {
                      display: flex;
                      justify-content: space-evenly;
                      align-content: center;
                  }
                  .logo__block {
                      border-bottom: 1px dotted #a91c1c;
                      margin-top: 100px;
                  }
                  .logo__block p, .footer, p {
                      color: #a91c1c;
                  }
                  .invoice__head__child_contacts table tbody tr td {
                      font-style: italic;
                  }
                  .invoice {
                      background-color: #fff;
                      padding: 2rem;
                      width: fit-content;
                      max-height: 100%;
                  }
                  .invoice__head,
                  .invoice__how_to_pay {
                      display: flex;
                      justify-content: space-between;
                  }
                  .invoice__body {
                      display: flex;
                  }

                  .bill-items-length{
                  margin-top:50px;
                  }

                  .invoice__body table tr th {
                      background: #a91c1c;
                      color: white;
                      text-align: left;
                      vertical-align: center;
                  }
                  .invoice__body table tr {
                      background-color: rgba(136, 136, 136, .1);
                      border: 1px solid #ddd;
                      padding: .35em;
                  }
                  .logo__block, .footer {
                      text-align: center;
                  }
                  .full_table {
                      width: 100%;
                  }
                  table tbody tr td {
                      padding: .5rem;
                  }
                  @media (max-width: 576px) {
                      .btn_control_block {
                          display: flex;
                          flex-direction: row;
                      }
                  }
                  @media print {
                      .page-break { 
                          display: block; 
                          page-break-before: always; 
                      }
                      @page {
                          size: A4;
                          margin: 0mm;
                      }
                      html, body {
                          width: 1024px;
                      }
                      body {
                          margin: 0 auto;
                          line-height: 1em;
                          word-spacing: 2px;
                          letter-spacing: 0.2px;
                          font-size: 17px;
                          color: black;
                          width: 100%;
                          float: none;
                      }
                      .invoice__body table tr th {
                          padding: 5px;
                      }
                      .footer {
                          bottom: 0;
                          width: 100%;
                          text-align: center;
                      }
                      .invoice {
                          background-color: transparent;
                      }
                      .listingContainer {
                          page-break-inside: avoid;
                      }
                      h1 {
                          font: 28px "Times New Roman", Times, serif;
                      }
                      h2 {
                          font: 24px "Times New Roman", Times, serif;
                      }
                      h3 {
                          font: 20px "Times New Roman", Times, serif;
                      }
                      a:link, a:visited {
                          color: #781351;
                          background: transparent;
                          text-decoration: none;
                      }
                      a[href]:after {
                          content: "" !important;
                      }
                      a[href^="http:"] {
                          color: #000;
                      }
                      #header {
                          height: 75px;
                          font-size: 24pt;
                          color: black;
                      }
                  }
              </style>
          </head>
          <body onload="window.print()">
              ${innerContents}
          </body>
      </html>`);

  popUpWindow.document.close();
}


}
