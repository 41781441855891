import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-recon-transaction",
  templateUrl: "./recon-transaction.component.html",
  styleUrls: ["./recon-transaction.component.scss"],
})
export class ReconTransactionComponent
  extends SharedClassComponent
  implements OnInit
{
  title: string = "ZanMalipo successful transactions";

  today = new Date();
  currentDate: any;
  currentUser;
  datasource = [];

  sevenDaysBack: Date = new Date();

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.sevenDaysBack.setDate(this.today.getDate() - 7);
    this.paramwinfrm = new FormGroup({
      txnDate: new FormControl(
        this.today,
        Validators.compose([Validators.required])
      ),
    });

    this.observerCall();
    this.currentUser = this.authService.getUserDetails().username;
  }

  getReconData() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Please fill all the required fields");
      return;
    }
    const dt = this.paramwinfrm.get("txnDate").value;
    const txnDatestring =
      dt.getFullYear() +
      "-" +
      ("0" + (dt.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dt.getDate()).slice(-2);

    const data = {
      requestType: "BILLRECONS",
      txnDate: txnDatestring,
      reconType: "1",
    };

    this.datasource = [];
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const servRes = res.json();
        this.spinner.hide();

        if (servRes.statusId == 2000) {
          this.toastr.success("Operation successfully.");
          this.datasource = servRes.data;
        } else {
          this.toastr.error(servRes.statusMessage, "Request Failed");
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong, failed to create the bill.");
      }
    );
  }

  toolBarPreparing(e, refresh_action) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        type: "default",
        onClick: refresh_action.bind(this),
      },
    });
  }
}
