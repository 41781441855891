import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../../shared-class/shared-class.component';

@Component({
  selector: 'app-bank-branches',
  templateUrl: './bank-branches.component.html',
  styleUrls: ['./bank-branches.component.scss']
})
export class BankBranchesComponent  extends SharedClassComponent implements OnInit {
    //variables defination
    paramswinpnl: boolean = false;
    paramwinfrm: FormGroup;
    endpointcall: string;
    paramsDataSets: any;
    param_id: any;
    params_actions: any;
    response: any;
    title: string = 'Register Institution';
    model_name: string = 'sp/list';

    ngOnInit() {
        // sets the current page title in index.html title tag
      this.appInfo.setTitle(this.title);

      this.paramwinfrm =  new FormGroup({
        requestType: new FormControl('SP_CREATE', Validators.compose([])),
        spId: new FormControl('', Validators.compose([])),
        spCode: new FormControl('', Validators.compose([])),
        spAlias: new FormControl('', Validators.compose([Validators.required])),
        spName: new FormControl('', Validators.compose([Validators.required])),
        status: new FormControl('', Validators.compose([])),
        createdBy: new FormControl('', Validators.compose([])),
        modifiedBy: new FormControl('', Validators.compose([])),
        checkedBy: new FormControl('', Validators.compose([])),
        createdAt: new FormControl('', Validators.compose([])),
        modifiedAt: new FormControl('', Validators.compose([])),
        checkedAt: new FormControl('', Validators.compose([])),
        status_id: new FormControl('', Validators.compose([])),
        logoUrl: new FormControl('', Validators.compose([]))
      });

      this.getServiceProvidersList();


      this.observerCall();

    }
    refreshDataGrid() {
      this.getServiceProvidersList();
    }

    onParamsToolBarPreparing(e) {
      e.toolbarOptions.items.unshift( {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'New SP',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewParameter.bind(this)
        }
      }, {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
         onClick: this.refreshDataGrid.bind(this)
        }
      });

    }
}
