import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { confirm } from "devextreme/ui/dialog";
import { SharedClassComponent } from "src/app/configurations/shared-class/shared-class.component";

@Component({
  selector: "app-service-entry",
  templateUrl: "./service-entry.component.html",
  styleUrls: ["./service-entry.component.scss"],
})
export class ServiceEntryComponent
  extends SharedClassComponent
  implements OnInit
{
  serviceEntryList: any;
  paramswinpnlN: boolean = false;
  showAddButton = false;
  showUpdateButton = false;
  serviceDate: any;
  serviceType: any;
  serviceAmount: any;
  Id: any;
  hserviceDate: any;
  hserviceType: number = 0;
  hserviceAmount: any;
  currency: any;
  serviceProvidedTo: any;

  customerNo: any;
  currencyDts = [
    {
      id: "TZS",
      text: "TZS",
    },
    {
      id: "USD",
      text: "USD",
    },
  ];
  spCatList: any;
  currentDFate = new Date();
  institutionCode = this.authService.getUserDetails().spId;
  customerList: any;
  paymentStatus: any;
  paymentStatusOne: Boolean = true;
  ngOnInit() {
    this.getServiceEntry();
    this.paramwinfrm = new FormGroup({
      hserviceType: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),

      currency: new FormControl(
        "TZS",
        Validators.compose([Validators.required])
      ),

      hserviceDate: new FormControl(
        this.currentDFate,
        Validators.compose([Validators.required])
      ),
      serviceAmount: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      paymentStatus: new FormControl("", Validators.compose([])),

      serviceProvidedTo: new FormControl("", Validators.compose([])),
      customerNo: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });
    this.getCategoryBySp();
    this.getCustomerList();
  }

  getCustomerList() {
    const data = {
      requestType: "SP_CUSTOMER_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.customerList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  getCategoryBySp() {
    const data = {
      requestType: "SP_SERVICE_CATEGORY_LIST",
      spId: this.institutionCode,
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const srvRes = res.json();

        if (srvRes.statusId == 2000) {
          this.spCatList = srvRes.data;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Failed to load Service Categories",
            srvRes.statusMessage
          );
        }
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error);
        this.spinner.hide();
      }
    );
  }

  getServiceEntry() {
    const data = {
      requestType: "SP_SERVICE_RECEIVERS_LIST",
    };
    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.serviceEntryList = response.data;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  onParamsToolBarPreparingN(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: "Add",
        type: "default",
        icon: "fa fa-plus",
        onClick: this.addServiceEntry.bind(this),
      },
    });
  }

  addServiceEntry() {
    this.paramwinfrm.reset();
    this.showAddButton = true;
    this.showUpdateButton = false;
    this.paramswinpnlN = true;
  }

  postData() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all required fields to proceed");
      return;
    }

    const data = {
      requestType: "SP_SERVICE_RECEIVERS_CREATE",
      serviceType: this.paramwinfrm.get("hserviceType").value,
      serviceDate: this.paramwinfrm.get("hserviceDate").value,
      serviceAmount: this.paramwinfrm.get("serviceAmount").value,
      currency: this.paramwinfrm.get("currency").value,
      serviceProvidedTo: this.paramwinfrm.get("serviceProvidedTo").value,
      customerNo: this.paramwinfrm.get("customerNo").value,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getServiceEntry();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  update() {
    if (this.paramwinfrm.invalid) {
      this.toastr.error("Enter all required fields to proceed");
      return;
    }

    const data = {
      requestType: "SP_SERVICE_RECEIVERS_UPDATE",
      serviceType: this.paramwinfrm.get("hserviceType").value,
      serviceDate: this.paramwinfrm.get("hserviceDate").value,
      serviceAmount: this.paramwinfrm.get("serviceAmount").value,
      currency: this.paramwinfrm.get("currency").value,
      serviceProvidedTo: this.paramwinfrm.get("serviceProvidedTo").value,
      customerNo: this.paramwinfrm.get("customerNo").value,
      paymentStatus:
        this.paramwinfrm.get("paymentStatus").value == true ? 1 : 0,
      id: this.Id,
    };

    this.spinner.show();
    this.utilities.postServiceCall(data).subscribe(
      (res) => {
        const response = res.json();
        if (response.statusId == 2000) {
          this.toastr.success(response.statusMessage);
          this.getServiceEntry();
          this.paramswinpnlN = false;
        } else {
          this.toastr.error(response.statusMessage, "Error");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "Something went wrong please try again",
          "Request Failed"
        );
      }
    );
  }

  delete() {
    const result = confirm("Are you sure you want to delete?", "");

    result.then((dialogResult) => {
      if (dialogResult) {
        const data = {
          requestType: "SP_SERVICE_RECEIVERS_DELETE",
          id: this.Id,
        };

        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const response = res.json();
            if (response.statusId == 2000) {
              this.toastr.success(response.statusMessage);
              this.getServiceEntry();
              this.paramswinpnlN = false;
            } else {
              this.toastr.error(response.statusMessage, "Error");
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              "Something went wrong please try again",
              "Request Failed"
            );
          }
        );
      }
    });
  }

  previewcustomerNo(e) {
    const pstatus = e.data.paymentStatus == 1 ? true : false;
    this.paramwinfrm.get("paymentStatus").setValue(pstatus);
    if (e.data) {
      this.showAddButton = false;
      this.showUpdateButton = true;
      this.hserviceDate = e.data.serviceDate;
      this.hserviceType = e.data.serviceType
        ? Number(e.data.serviceType)
        : e.data.serviceType;
      this.serviceAmount = e.data.serviceAmount;
      this.currency = e.data.currency;
      this.customerNo = e.data.customerNo;
      this.serviceProvidedTo = e.data.serviceProvidedTo;
      this.paymentStatus = e.data.paymentStatus; 
      this.Id = e.data.Id;
      this.paramswinpnlN = true;
    }

    else {
      this.showAddButton = true;
      this.showUpdateButton = false;
    }
  }
}
