import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SharedClassComponent } from '../configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss']
})
export class ServiceProviderComponent extends SharedClassComponent implements OnInit {
  //variables defination
  paramswinpnl: boolean = false;
  paramwinfrm: FormGroup;
  categoryInfo: FormGroup;
  endpointcall: string;
  paramsDataSets: any;
  param_id: any;
  params_actions: any;
  response: any;
  title: string = 'Service Provider Category';
  model_name: string = 'sp/category';

  ngOnInit() {
      // sets the current page title in index.html title tag
    this.appInfo.setTitle('Service Provider Category');

    this.paramwinfrm =  new FormGroup({
      requestType: new FormControl('SP_SERVICE_CATEGORY_CREATE', Validators.compose([])),
      catId: new FormControl('', Validators.compose([])),
      catName: new FormControl('', Validators.compose([Validators.required])),
      catSpId: new FormControl( sessionStorage.getItem('spId'), Validators.compose([Validators.required])),
      accNo: new FormControl(null, Validators.compose([Validators.required])),
      status: new FormControl('', Validators.compose([]))
    });

    
    
    this.categoryInfo =  new FormGroup({
  
      spName: new FormControl(sessionStorage.getItem('spName')),
      catName: new FormControl('', Validators.compose([Validators.required])),
      catAcc: new FormControl('', Validators.compose([Validators.required])),
  
    });

    this.getServiceProviderCategories();
    this.getServiceProviders();
    this.observerCall();

   
  }
  refreshDataGrid() {
    this.getServiceProviderCategories();
    this.getServiceProviders();
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'New Category',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewParameter.bind(this)
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        type: 'default',
       onClick: this.refreshDataGrid.bind(this)
      }
    });

  }

  categoryCreate() {
    console.log("called")
        if(this.categoryInfo.invalid){
          this.toastr.error("Account number is required")
          return;
        }
        const data = {
          "requestType": 'SP_SERVICE_CATEGORY_CREATE',
          "catSpId": sessionStorage.getItem('spId'),    
          "catAcc":  this.categoryInfo.get("catAcc").value,   
          
           "catName": this.categoryInfo.get("catName").value,   

        };
  
    
        console.log(data)
    
    
        this.spinner.show();
        this.utilities.postServiceCall(data).subscribe(
          (res) => {
            const srvRes = res.json();
    
            if (srvRes.statusId == 2000) {
              this.getServiceProviderCategories()
this.paramswinpnl=false;        
    this.toastr.success(srvRes.statusMessage)

        this.spinner.hide();
            }
          },
          (error) => {
            this.toastr.error(error);
            this.spinner.hide();
          }
        );
      }
    
}
