import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import XLSX from 'xlsx-style/dist/xlsx';
import { confirm } from 'devextreme/ui/dialog';
import csv2json from 'csvjson-csv2json/csv2json';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-reconciliation-status',
  templateUrl: './reconciliation-status.component.html',
  styleUrls: ['./reconciliation-status.component.scss']
})
export class ReconciliationStatusComponent extends SharedClassComponent implements OnInit {

  title: string = 'Reconciliations';
  reconCheckEndPoint = 'realbus_api/sx/recon/log';
  reconPushEndPoint = 'realbus_api/sx/recon/misc';
  reconPushByDateEndPoint = 'realbus_api/sx/recon/batch';
  reconStatusDatasource = [];
  unreconciliedTxnsDatasource = [];
  openFileUploadDialog = false;
  value = [];
  uploadFailedMessage = '';
  showAlertDialog = false;
  showSuccessDialog = false;
  showWarningDialog = false;
  alertReason = '';
  datagridHeight2 = 69;
  today = new Date();

  tab_paneldata: any = [
    {
    ID: 1,
    icon: 'fa fa-check-circle',
    name: 'Reconciliation Status',
   },
   {
    ID: 2,
    icon: 'fa fa-calendar',
    name: 'Reconcile By Date',
   },
   {
    ID: 3,
    icon: 'fa fa-file-excel-o',
    name: 'Reconcile By Excel',
   }
];
  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.getReconciliationStatus();
    this.paramwinfrm = new FormGroup({
      startDate: new FormControl(this.today, Validators.compose([Validators.required]))
    });
  }

  
  getReconciliationStatus() {
    this.utilities.txnPushpPostServiceCall({}, this.reconCheckEndPoint).subscribe(res => {
      this.spinner.hide();
      const apiResponse = res.json();
      if (apiResponse.statusId == 2000) {
        this.toastr.success(apiResponse.statusMessage);
        this.reconStatusDatasource = apiResponse.data;
      } else {
        this.toastr.error(apiResponse.statusMessage);
      }
    }, error => {
      this.toastr.error(error, 'Request Failed');
    });

  }

  openPopUp() {
    this.openFileUploadDialog = true;
    this.value = [];
  }
  closePopUp() {
    this.openFileUploadDialog = false;
  }


  submitUnreconciledData() {
    if (this.unreconciliedTxnsDatasource.length < 1) {
      this.showAlertDialog = true;
      this.alertReason = `You can not submit empty list, please upload excel file from GePG which contains unreconcilied transactions information.`;
      return;
    }

    this.spinner.show();

    for (const iterator of this.unreconciliedTxnsDatasource) {

       if(`${iterator.controlNo}`.trim() == '') {
        this.showAlertDialog = true;
        this.alertReason = `Control number field can not be empty, please check the submitted list`;
        return;
       }

       if(`${iterator.txnDate}`.trim() == '') {
        this.showAlertDialog = true;
        this.alertReason = `Transaction date field can not be empty, please check the submitted list`;
        return;
      }

      if(`${iterator.refId}`.trim() == '') {
        this.showAlertDialog = true;
        this.alertReason = `Bank Reference field can not be empty, please check the submitted list`;
        return;
      }
     }

    this.utilities.txnPushpPostServiceCall(this.unreconciliedTxnsDatasource, this.reconPushEndPoint).subscribe(res => {
        const serverResponse = res.json();
        this.spinner.hide();
        if (serverResponse.statusId == 2000) {
        this.unreconciliedTxnsDatasource = [];
        this.showSuccessDialog = true;
        this.alertReason = 'Unreconcilied transactions processed successfully.';
        } else {
          this.showAlertDialog = true;
          this.alertReason = serverResponse.statusMessage;
        }
      }, error => {
             this.spinner.hide();
             this.toastr.error('Something went wrong, please try again');
      });
  }

  submitUnreconciliedReconsByBatchDate() {
    if (this.paramwinfrm.invalid) {
      this.showAlertDialog = true;
      this.alertReason = 'Please select failed reconciliation batch date';
      return;
    }

    this.spinner.show();

    const dd = String(new Date(this.paramwinfrm.get('startDate').value).getDate()).padStart(2, '0');
    const mm = String(new Date(this.paramwinfrm.get('startDate').value).getMonth() + 1).padStart(2, '0'); // January is 0
    const yyyy = new Date(this.paramwinfrm.get('startDate').value).getFullYear();

   //  this.currentDate = dd + '-' + mm + '-' + yyyy;
    const reconBatchDate = yyyy + '-' + mm + '-' + dd;

    const data = {
      batchDate: reconBatchDate
    }

    this.utilities.txnPushpPostServiceCall(data, this.reconPushByDateEndPoint).subscribe(res => {
      const serverResponse = res.json();
      this.spinner.hide();
      if (serverResponse.statusId == 2000) {
      this.showSuccessDialog = true;
      this.alertReason = 'Unreconcilied transactions processed successfully.';
      } else {
        this.showAlertDialog = true;
        this.alertReason = serverResponse.statusMessage;
      }
    }, error => {
           this.spinner.hide();
           this.toastr.error('Something went wrong, please try again');
    });

  }

  toolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getReconciliationStatus.bind(this)
        }
      }
    );
  }

  // UPLOAD EXCEL FILE OPERATIONS

  hideDialog() {
    this.showAlertDialog = false;
  }

  hideSuccessDialod() {
    this.showSuccessDialog = false;
  }


  // UPLOAD EXCEL (.csv)

  onFileValueChanged(event) {
    this.unreconciliedTxnsDatasource = [];
    let data = {};
    let totalContributionsAmt = 0;
    this.uploadFailedMessage = 'Loading....';

    /*
      check file type
      if it is xlsx or xls call parseXLXS() function
    */
   if (`${event.file.name}`.endsWith('.xlsx') || `${event.file.name}`.endsWith('.xls')) {
     this.toastr.info('Excel File Loading (xlsx or xls detected)');
    this.parseXLXS(event);
    return;
   }


   this.spinner.show();
    const reader = new FileReader();
    reader.onload = () => {
      this.spinner.show();
      this.uploadFailedMessage =
        'Converting excel data format to json format....';
      const text = reader.result;
      const json = csv2json(text, { parseNumbers: true });
      data = {
        data: json,
      };
      let counter = 0;
      // tslint:disable-next-line: prefer-for-of

      for (let i = 0; i < json.length; i++) {

        if (!json[0].hasOwnProperty('CONTROL_NUMBER')) {
          // if (json.length < this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Please include CONTROL_NUMBER header in your excelsheet on control number column.`;
            this.spinner.hide();
            return;
          // }
        }

        if (!json[0].hasOwnProperty('TRANSACTION_DATE')) {
          // if (json.length < this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Please include TRANSACTION_DATE header in your excelsheet on transaction date column.`;
            this.spinner.hide();
            return;
          // }
        }

        if (!json[0].hasOwnProperty('BANK_REFERENCE')) {
          // if (json.length < this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Please include BANK_REFERENCE header in your excelsheet on bank reference column.`;
            // this.toastr.error('Please include memberName header in your excelsheet for members names column..');
            this.spinner.hide();
            return;
          // }
        }

        
        if (json[i].CONTROL_NUMBER == null || json[i].CONTROL_NUMBER == '' || json[i].CONTROL_NUMBER === undefined) {
          if (json.length <= this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Control number is missing in the excel sheet.`;
            // this.toastr.error('Member Salary is required, please update your member\'s salary in the excel sheet 2.');
            this.spinner.hide();
            return;
          }
        }

        if (json[i].TRANSACTION_DATE == null || json[i].TRANSACTION_DATE == '' || json[i].TRANSACTION_DATE == undefined) {
          if (json.length <= this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Transaction Date is missing in the excel sheet`;
            // this.toastr.error('Member Name is required, please include it in your excelsheet.');
            this.spinner.hide();
            return;
          }
        }

        if (json[i].BANK_REFERENCE == null || json[i].BANK_REFERENCE == '' || json[i].BANK_REFERENCE == undefined) {
          if (json.length <= this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Bank reference is missing in the excel sheet`;
            // this.toastr.error('Member Name is required, please include it in your excelsheet.');
            this.spinner.hide();
            return;
          }
        }
          this.unreconciliedTxnsDatasource.push({
            id: ++counter,
            controlNo: json[i].CONTROL_NUMBER,
            txnDate: json[i].TRANSACTION_DATE,
            refId: json[i].BANK_REFERENCE
          });
        // this.unreconciliedTxnsDatasource = json;
        this.uploadFailedMessage = 'Conversion completed....';
        }
      this.spinner.hide();
    };
    reader.onloadend = () => {
      this.spinner.hide();
      this.uploadFailedMessage = 'Data uploaded successfully....';
      this.openFileUploadDialog = false;
    };
    reader.readAsText(event.file);

  }



  // UPLOAD EXCEL (.xlsx)

  parseXLXS = (event) => {
    this.unreconciliedTxnsDatasource = [];
    this.uploadFailedMessage = 'Loading....';
    let reader = new FileReader();

    reader.onload = () => {
      let data = reader.result;
      let workbook = XLSX.read(data, {
        type: 'binary'
      });
      workbook.SheetNames.forEach((sheetName) => {
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        let json = XL_row_object;
        this.uploadFailedMessage =
        'Converting excel data format to json format....';
      
      let counter = 0;
      // tslint:disable-next-line: prefer-for-of
      this.spinner.show();
      for (let i = 0; i < json.length; i++) {
        if (!json[0].hasOwnProperty('CONTROL_NUMBER')) {
          // if (json.length < this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Please include CONTROL_NUMBER header in your excelsheet on control number column.`;
            this.spinner.hide();
            return;
          // }
        }

        if (!json[0].hasOwnProperty('TRANSACTION_DATE')) {
          // if (json.length < this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Please include TRANSACTION_DATE header in your excelsheet on transaction date column.`;
            this.spinner.hide();
            return;
          // }
        }

        if (!json[0].hasOwnProperty('BANK_REFERENCE')) {
          // if (json.length < this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Please include BANK_REFERENCE header in your excelsheet on bank reference column.`;
            // this.toastr.error('Please include memberName header in your excelsheet for members names column..');
            this.spinner.hide();
            return;
          // }
        }

        if (json[i].CONTROL_NUMBER == null || json[i].CONTROL_NUMBER == '' || json[i].CONTROL_NUMBER === undefined) {
          if (json.length <= this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Control number is missing in the excel sheet.`;
            // this.toastr.error('Member Salary is required, please update your member\'s salary in the excel sheet 2.');
            this.spinner.hide();
            return;
          }
        }

        if (json[i].TRANSACTION_DATE == null || json[i].TRANSACTION_DATE == '' || json[i].TRANSACTION_DATE == undefined) {
          if (json.length <= this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Transaction Date is missing in the excel sheet`;
            // this.toastr.error('Member Name is required, please include it in your excelsheet.');
            this.spinner.hide();
            return;
          }
        }

        if (json[i].BANK_REFERENCE == null || json[i].BANK_REFERENCE == '' || json[i].BANK_REFERENCE == undefined) {
          if (json.length <= this.unreconciliedTxnsDatasource.length) {
            this.showAlertDialog = true;
            this.alertReason = `Bank reference is missing in the excel sheet`;
            // this.toastr.error('Member Name is required, please include it in your excelsheet.');
            this.spinner.hide();
            return;
          }
        }
          this.unreconciliedTxnsDatasource.push({
            id: ++counter,
            controlNo: json[i].CONTROL_NUMBER,
            txnDate: json[i].TRANSACTION_DATE,
            refId: json[i].BANK_REFERENCE
          });
                
        // this.unreconciliedTxnsDatasource = json;
        this.uploadFailedMessage = 'Conversion completed....';
        }
      this.spinner.hide();
      })
    };

    reader.onloadend = () => {
      this.uploadFailedMessage = 'Data uploaded successfully....';
      this.openFileUploadDialog = false;
    };

    reader.onerror = (ex) => {
    };

    reader.readAsBinaryString(event.file);
  };

}
