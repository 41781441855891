import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from '../../shared-class/shared-class.component';

@Component({
  selector: 'app-user-statuses',
  templateUrl: './user-statuses.component.html',
  styleUrls: ['./user-statuses.component.scss']
})
export class UserStatusesComponent extends SharedClassComponent implements OnInit {
  title:string= 'User Statuses';
  model_name:string='status';
  //variables defination
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  paramsDataSets:any;
  param_id:any;
  response:any;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.paramwinfrm =  new FormGroup({
      name: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([])),
      is_enabled: new FormControl('', Validators.compose([])),
      is_super_group: new FormControl('', Validators.compose([])),
      id: new FormControl('', Validators.compose([])),
      status_id: new FormControl('', Validators.compose([]))
    });
    this.onGetParamsdetails();
    // Controls the datagrid height and max rows to display
    this.observerCall();

  }
  funcGetCheckValue(is_enabled) {

      if (is_enabled == 1) {
        return true;
      } else {
        return false;
      }
  }

}
