import { Component, OnInit } from '@angular/core';

import {  DxContextMenuModule, DxMenuModule } from 'devextreme-angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { SharedClassComponent } from '../../shared-class/shared-class.component';
@Component({
  selector: 'app-navigation-types',
  templateUrl: './navigation-types.component.html',
  styleUrls: ['./navigation-types.component.scss']
})
export class NavigationTypesComponent extends SharedClassComponent implements OnInit {
  title:string= 'Navigation Types';
  model_name:string='navigation/types';
  //variables defination
  paramswinpnl: boolean=false;
  paramwinfrm:FormGroup;
  endpointcall:string;
  userRolesDta:any;
  param_id:any;
  response:any;

  ngOnInit() {
    // sets the current page title in index.html title tag
    this.appInfo.setTitle(this.title);
    this.onGetParamsdetails();
   // Controls the datagrid height and max rows to display
    this.observerCall();
  }
}
