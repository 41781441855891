import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outgoing-tiss-response',
  templateUrl: './outgoing-tiss-response.component.html',
  styleUrls: ['./outgoing-tiss-response.component.scss']
})
export class OutgoingTissResponseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
